.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.cracha-column-background .ant-table-cell {
  background-color: #b64141; /* Change to your desired color */
}

/* To ensure the background color applies to both header and cells */
.ant-table-thead > tr > th.name-column-background {
  background-color: #db2b2b; /* Change to your desired color */
}

.name-column-header {
  background-color: #fafafa; /* Change to your desired color */
}

.name-column-cell {
  background-color: #fafafa; /* Change to your desired color */
}